import React from 'react';
import { useSelector } from "react-redux";
import './slider.styles.scss';

const Slider = () => {
  const xprops = useSelector(state => state);
  return (
    <React.Fragment>
      <div className="sliderID">
        <div className="sliderWrapper">
          <div className="sliderQuoteWrapper">
            <span className="sliderAttachLeft"></span>
            {
              xprops.project.language.short === "TR" ?
              <React.Fragment>
                <h2 className="sliderQuote" title="SEN OL Kİ MEDYANIN SESİ TAM OLSUN">SEN OL Kİ MEDYANIN SESİ TAM OLSUN</h2>
                <p className="sliderText">
                  <strong>"Sensiz Olmaz Sessiz Olmaz"</strong>, kadınların her alanda daha güçlü, daha görünür ve daha etkili olmalarını desteklemek amacıyla kurulmuş bir sosyal girişimdir. Bu girişim kadınların, toplumsal ve bireysel yaşamda karşılaştıkları engelleri aşmalarına yardımcı olacak araçlar ve ortamlar paylaşmayı, medya ve kamusal alanda seslerini daha güçlü duyurabilmeleri için olanaklar sağlamayı amaçlar. 
                </p>
                <p className="sliderText">
                Sensiz Olmaz Sessiz Olmaz, kadın uzmanlardan oluşan veri tabanı ve eğitimler ile kadınların birikimlerini medyada duyurmalarına olanak tanır. Sessiz Olmaz blog ve sosyal medya platformları üzerinden farkındalık çalışmaları desteklenerek  kadınların görünür olması, koçluk programlarıyla ise iş hayatında güçlenmesi hedefleniyor. 
                </p>
                <p className="sliderText">
                  <span className="sliderAttachRight"></span>
                </p>
              </React.Fragment>
              :
              <React.Fragment>
              <h2 className="sliderQuote" title="SEN OL Kİ MEDYANIN SESİ TAM OLSUN">BE THE ONE TO MAKE THE MEDIA'S VOICE FULL</h2>
              <p className="sliderText">
                As a platform aiming to enhance the visibility of women, "Sensiz Olmaz Sessiz Olmaz- Not Without You Not Without Your Voice" strives to amplify women's voices in traditional and social media, as well as other public spaces.
              </p>
              <p className="sliderText">
                By contributing to the more active participation of female experts in outlets such as television programs, civil society events, and private sector meetings, the initiative draws attention to gender equality.
              </p>
              <p className="sliderText">
              The endeavor presents a database consisting of female experts, providing them with media training and supporting awareness campaigns through the "Sessiz Olmaz" blog and social media. This allows women to share their professional experiences and expertise with broader audiences.
                <span className="sliderAttachRight"></span>
              </p>

              </React.Fragment>
            }
          </div>        
        </div>
      </div>
    </React.Fragment>
  );
}

export default Slider;