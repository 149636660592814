import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import './aboutlayer.styles.scss';

const AboutLayer = () => {
  const xprops = useSelector(state => state);
  return(
    <React.Fragment>
    <div className="mainWorksWrapper">
      <div className="mainWorksHeader">
        <img className="mainWorksHeaderImg" src="/assets/images/middle-logo.png" alt="Sensiz Olmaz Sessiz Olmaz" />
        <h2 className="mainWorksHeaderTitle">{xprops.project.language.keywords.calismalar}</h2>
      </div>
      
      <div className="workshopWrapper">
        <div className="workshop">
          <Link to='/uzman-bul'><img className="workshop-image" src="/assets/images/soso-uye-olan-kadinlar.jpg" alt={xprops.project.language.keywords.kadinUzmanVeriTabani} /></Link>
          <div className="workshop-title-wrapper">
            <Link to='/uzman-bul' className="aboutTitleLinks"><h3 className="workshop-title">{xprops.project.language.keywords.kadinUzman}<span className="italic">{xprops.project.language.keywords.veriTabani}</span></h3></Link>
          </div>
          <div className="workshop-content-wrapper">
            <p className="workshop-content">
              {
                xprops.project.language.short === "TR" ? 
                <React.Fragment>
                Çeşitli alanlarda uzmanlaşmış kadınların profillerini oluşturabildikleri bir platform olan uzman veri tabanı, medya, sivil toplum ve özel sektör temsilcilerinin erişebileceği bir kaynak haline getirildi. Mayıs 2021'de faaliyete geçen bu uzman havuzunun şu anda 350 üyesi bulunmaktadır. Siz de uzmanlığınızı paylaşmak ve daha geniş bir kitleye ulaşmak için uzman havuzuna katılmak için <Link to="/signup">buraya</Link> tıklayabilirsiniz. 
                </React.Fragment>
                  :
                  <React.Fragment>
The expert database is a platform where women specializing in various fields can create their profiles, turning it into a resource accessible to media, civil society, and private sector representatives. Launched in May 2021, the expert pool currently has 350 members. If you wish to share your expertise and reach a wider audience, you can click here to join the expert pool.
                  </React.Fragment>
                  
              }

            
            </p>
          </div>
        </div>

        <div className="workshop">
          <Link to='/egitimler'><img className="workshop-image" src="/assets/images/egitimler.jpg" alt={xprops.project.language.keywords.medyaEgitimleri} /></Link>
          <div className="workshop-title-wrapper">
            <Link to='/egitimler' className='aboutTitleLinks'><h3 className="workshop-title">{xprops.project.language.keywords.medyaEgitimleri}</h3></Link>
          </div>
          <div className="workshop-content-wrapper">
            <p className="workshop-content">
            {
                xprops.project.language.short === "TR" ? 
                <React.Fragment>
                Alanında uzman kadınların medya ile ilişkilerini daha etkili ve verimli kurabilmeleri için medya eğitimleri düzenleniyor. Deneyimli medya uzmanları tarafından gönüllü kadın uzmanlar “medya nasıl çalışır, medyayı etkili ve verimli şekilde kullanmanın yolları” nelerdir konulu teorik ve pratik çalışma içeren eğitimler ile gerçekleştiriliyor. Mayıs 2021’den beri 138 kadın uzman eğitimlere katıldı.            
                </React.Fragment>
                  :
                  <React.Fragment>
                  Media training sessions are organized to help women experts establish more effective and efficient relationships with the media. Voluntary female experts, guided by experienced media professionals, conduct theoretical and practical training sessions on topics such as "how the media works" and "ways to use the media effectively and efficiently." Since May 2021, 138 women experts have participated in these training sessions.
                  </React.Fragment>
              }
            </p>
          </div>
        </div>

        <div className="workshop">
          <Link to='/mentorluk'><img className="workshop-image" src="/assets/images/kadin-uzmanlar-icin-kocluk-calismasi.jpg" alt={xprops.project.language.keywords.mentorlukCalismalari} /></Link>
          <div className="workshop-title-wrapper">
            <Link to='/mentorluk' className='aboutTitleLinks'><h3 className="workshop-title">{xprops.project.language.keywords.mentoring}<span className="italic">{xprops.project.language.keywords.calismalari}</span></h3></Link>
          </div>
          <div className="workshop-content-wrapper">
            <p className="workshop-content">
            {
                xprops.project.language.short === "TR" ? 
                <React.Fragment>
                    Kadınların iş hayatında kariyerlerini güçlendirmelerine ve karşılaştıkları zorlukları aşmalarına yardımcı olmayı amaçlayan koçluk çalışmaları gerçekleştiriliyor. 2022-2023 yıllarında, 10'un üzerinde kadın uzman mentorlük çalışmasından faydalandı. 
                </React.Fragment>
                  :
                  <React.Fragment>
                  Coaching sessions are conducted with the aim of empowering women in their careers and helping them overcome challenges in the professional sphere. In the years 2022-2023, over 10 women have benefited from mentorship programs.
                  </React.Fragment>
                  
              }

              
            </p>
          </div>
        </div>       
        <div className="workshop">
          <a href='https://www.sessizolmaz.org'><img className="workshop-image" src="/assets/images/roportaj.jpg" alt={xprops.project.language.keywords.sessizOlmazBlog} /></a>
          <div className="workshop-title-wrapper">
            <a href='https://www.sessizolmaz.org' className='aboutTitleLinks'><h3 className="workshop-title">{xprops.project.language.keywords.sessizOlmazBlog}</h3></a>
          </div>
          <div className="workshop-content-wrapper">
            <p className="workshop-content">
              {
                xprops.project.language.short === "TR" ? 
                <React.Fragment>
                    <a href="https://www.sessizolmaz.org"><b>https://www.sessizolmaz.org</b></a>
                    <br/><br/>                  
                    Sessiz Olmaz blogunda çeşitli alanlarda makaleler, röportajlar ve podcastler paylaşılıyor. Bloga uzmanlık alanınızdan bir yazı ile katkıda bulunmak isterseniz, lütfen bizimle iletişime geçin. 
                </React.Fragment>
                  :
                  <React.Fragment>
                    NOT WITHOUT YOUR VOICE - SESSİZ OLMAZ - BLOG
                    <br/><br/>                    
                    Visit <a href="https://www.sessizolmaz.org"><b>https://www.sessizolmaz.org</b></a> to explore a variety of articles, interviews, and podcasts on diverse subjects on the Sessiz Olmaz blog. If you would like to contribute an article related to your expertise, please feel free to contact us.
                  </React.Fragment>
                  
              }              
              
            </p>
          </div>
        </div>
        <div className="workshop">
          <Link to='/farkindalik-calismalari'><img className="workshop-image" src="/assets/images/duyuru.jpg" alt={xprops.project.language.keywords.farkindalikCalismalari} /></Link>
          <div className="workshop-title-wrapper">
            <Link to='/farkindalik-calismalari' className='aboutTitleLinks'><h3 className="workshop-title">{xprops.project.language.keywords.farkindalik}<span className="italic">{xprops.project.language.keywords.calismalari}</span></h3></Link>
          </div>
          <div className="workshop-content-wrapper">
            <p className="workshop-content">
            {
                xprops.project.language.short === "TR" ? 
                <React.Fragment>
                    Platformun aşağıdaki sosyal medya hesapları üzerinden kadınların medya ve kamusal alanda daha fazla görünür olmalarını teşvik eden içerikler paylaşılıyor: 
                    <br/>
                    <b>Twitter:</b> @sensizolmazorg <br/>
                    <b>Instagram:</b> sensizolmazorg <br/>
                    <b>LinkedIn:</b> Sensiz Olmaz Sessiz Olmaz <br/>
                    <b>Facebook:</b> Sensiz Olmaz Sessiz Olmaz <br/>
                    <b>Spotify:</b> Sensiz Olmaz Sessiz Olmaz Podcast <br/>
                </React.Fragment>
                  :
                  <React.Fragment>
                    The platform shares content through the following social media accounts to encourage the increased visibility of women in the media and public sphere:
                    <br/>
                    <b>Twitter:</b> @sensizolmazorg <br/>
                    <b>Instagram:</b> sensizolmazorg <br/>
                    <b>LinkedIn:</b> Sensiz Olmaz Sessiz Olmaz <br/>
                    <b>Facebook:</b> Sensiz Olmaz Sessiz Olmaz <br/>
                    <b>Spotify:</b> Sensiz Olmaz Sessiz Olmaz Podcast <br/>
                  </React.Fragment>
                  
              }                   

            </p>
          </div>
        </div>                
        <div className="workshop">
          <Link to='/toplantilar'><img className="workshop-image" src="/assets/images/toplantilar.jpg" alt={xprops.project.language.keywords.toplantilar} /></Link>
          <div className="workshop-title-wrapper">
            <Link to='/toplantilar' className='aboutTitleLinks'><h3 className="workshop-title margin-bottom-0">{xprops.project.language.keywords.toplantilar}</h3></Link>
          </div>
          <div className="workshop-content-wrapper">
            <p className="workshop-content">
            {
                xprops.project.language.short === "TR" ? 
                <React.Fragment>
                    Her yıl, platform çatısı altında kadın uzmanlar ve medya temsilcilerinin bir araya geldiği toplantılar ve çalıştaylar düzenlenmektedir. Bu etkinlikler, katılımcılara networking fırsatları sunmanın yanı sıra, karşılıklı talepleri ve beklentileri tartışma imkanı tanımaktadır. Aynı zamanda, kadın uzmanları medya dünyasıyla tanıştırma işlevini de görmektedir.
                </React.Fragment>
                  :
                  <React.Fragment>
                    Annually, the platform organizes meetings and workshops where women experts and media representatives come together. These events not only provide networking opportunities but also allow participants to discuss mutual requests and expectations. Additionally, these gatherings serve the purpose of introducing female experts to the media world.
                  </React.Fragment>
              }                  
            </p>
          </div>
        </div>          
      </div>

    </div>

    </React.Fragment>
  );
}

export default AboutLayer;